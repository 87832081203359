<template>
  <div class="task-add">
    <!-- <div class="main-Title bgff"><h2>Add Country</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form
          ref="postData"
          :model="postData"
          :rules="rules"
          label-width="80px"
          label-position="top"
          size="mini"
        >
          <el-form-item label="Code" prop="countryCode">
            <el-input v-model="postData.countryCode" placeholder="请输入国家代码" />
          </el-form-item>
          <el-form-item label="Name" prop="countryName">
            <el-input v-model="postData.countryName" placeholder="请输入国家名称" />
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12"
              ><el-button
                :loading="loading.btn"
                type="success"
                style="width: 100%;"
                @click="add('postData')"
                >保存</el-button
              ></el-col
            >
            <el-col :span="12"
              ><el-button
                :loading="loading.btn"
                type="primary"
                style="width: 100%;"
                @click="goOff()"
                >取消</el-button
              ></el-col
            >
          </el-row>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { handleCountryAdd } from '@/api/DDJ/v3/country.js';
  export default {
    data() {
      return {
        postData: {
          speedConfig: {},
        },
        loading: {
          btn: false,
        },
        rules: {
          countryCode: [
            {
              required: true,
              message: '请输入国家代码',
              trigger: 'blur',
            },
          ],
          countryName: [
            {
              required: true,
              message: '请输入国家名称',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    created() {
      var numberAndNulls = (rule, value, callback) => {
        console.log(value);
        if (value !== '') {
          setTimeout(() => {
            if (value > 1 || value < 0) {
              callback(new Error('只能输入小数'));
            } else {
              callback();
            }
          }, 0);
        } else {
          callback(new Error('不能为空！'));
        }
      };
      for (var i = 0; i < 24; i++) {
        this.$set(this.postData.speedConfig, i, 0);
        this.rules[`speedConfig[${i}]`] = [];
        this.rules[`speedConfig[${i}]`].push({ validator: numberAndNulls, trigger: 'blur' });
      }
    },
    methods: {
      // 保存
      add(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading.btn = true;
          let query = Object.assign({}, this.postData);
          query.speedConfig = JSON.stringify(this.postData.speedConfig);
          handleCountryAdd(query).then((res) => {
            console.log(res);
            this.loading.btn = false;
            this.$message({
              message: '添加成功',
              type: 'success',
            });
            this.$router.push({
              path: '/click/country/v3',
            });
          });
        });
      },
      goOff() {
        this.$router.push({
          path: '/click/country/v3',
        });
      },
    },
  };
</script>

<style></style>
